import { View, Image } from "react-native";
import React from "react";
import { Text, Stack, Surface } from "@react-native-material/core";
import styled from "styled-components";
import { AppBar } from "@react-native-material/core";
import Bruno from "./assets/Bruno.jpeg";
import YoutubeEnbed from "./components/YoutubeEnbed";

export default function App() {
  return (
    <MainWrapper>
      <AppBar
        style={{ alignItems: "center" }}
        color="#227fa1"
        title="Bruno Bogenschneider"
      />

      <Stack center>
        <Surface
          elevation={2}
          category="medium"
          style={{ width: 300, height: 300, marginTop: 30 }}
        >
          <Image
            source={{
              uri: Bruno,
            }}
            resizeMode="cover"
            style={{
              height: 300,
              width: 300,
              borderRadius: 4,
            }}
          />
        </Surface>
        <Surface
          elevation={4}
          category="medium"
          style={{
            width: 300,
            height: 300,
            marginTop: 30,
            backgroundColor: "#227fa1",
          }}
        >
          <View style={{ margin: 20 }}>
            <View>
              <Text variant="h5">Allergien</Text>
              <Text variant="h7" style={{ lineHeight: 20, margin: 10 }}>
                - Walnüsse
              </Text>
              <Text variant="h7" style={{ lineHeight: 20, margin: 10 }}>
                - Pekannüsse
              </Text>
              <Text variant="h7" style={{ lineHeight: 20, margin: 10 }}>
                - Pistazien
              </Text>
            </View>
            <View style={{ marginTop: 10 }}>
              <Text variant="h5">Weitere Krankheiten</Text>
              <Text variant="h7" style={{ lineHeight: 20, margin: 10 }}>
                - Asthma
              </Text>
              <Text variant="h7" style={{ lineHeight: 20, margin: 10 }}>
                - Neurodermitis
              </Text>
            </View>
          </View>
        </Surface>
        <Surface
          elevation={6}
          category="medium"
          style={{
            width: 300,
            height: 300,
            marginTop: 30,
            backgroundColor: "#99b3bd",
          }}
        >
          <YoutubeEnbed embedId="NMH8Q6LsMWU" />

          <Text variant="h6" style={{ lineHeight: 20, margin: 10 }}>
            Bei starken allergischen Reaktionen:
          </Text>
          <Text variant="h7" style={{ lineHeight: 20, margin: 10 }}>
            Epi-Pen benutzen, Kortison und Antiallergikum verabreichen und den
            Notarzt verständigen.
          </Text>
          {/* <Button
            title={status.isPlaying ? "Pause" : "Play"}
            onPress={() =>
              status.isPlaying
                ? video.current.pauseAsync()
                : video.current.playAsync()
            }
          /> */}
        </Surface>
        <Surface
          elevation={6}
          category="medium"
          style={{
            width: 300,
            height: 300,
            marginTop: 30,
            backgroundColor: "#227fa1",
          }}
        >
          <YoutubeEnbed embedId="9Ut8DbeA5IM" />
          <Text variant="h6" style={{ lineHeight: 20, margin: 10 }}>
            Bei Athemnot und Husten:
          </Text>
          <Text variant="h7" style={{ lineHeight: 20, margin: 10 }}>
            Asthmaspray verwenden.
          </Text>
        </Surface>
      </Stack>

      <View
        style={{
          backgroundColor: "#227fa1",
          padding: 30,
          marginTop: 30,
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text variant="h6">
          Kontakt: Nacira Bogenschneider / 0172 528 70 69{" "}
        </Text>
      </View>
    </MainWrapper>
  );
}

const MainWrapper = styled(View)`
  display: flex;
  width: 100%;
  height: 100%;
`;
